import { FC, useCallback, useEffect } from "react";
import { Box, Container, Grid } from "@mui/material";
import PageTitle from "../components/PageTitle";
import useSettings from "../hooks/useSettings";
import { useProperty } from "../hooks/use-property";
import { useTranslationWrapper } from "../hooks/use-translation-wrapper";
import { useDispatch, useSelector } from "../store";
import { fetchAllBoxes, selectAllBoxes } from "../slices/box.slice";
import { useSearchFilterParams } from "src/components/table/hooks/use-search-filter-params";
import { FilterMapInterface } from "src/components/table/filters/table-filters";
import { FreeTextFilter } from "src/components/table/filters/free-text-filter";
import { BoxTabs } from "src/features/boxes/box-tabs";

export const BoxesPage: FC = () => {
  const { settings } = useSettings();
  const { selectedProperty, selectedPropertiesPreview } = useProperty();
  const { t } = useTranslationWrapper();
  const boxes = useSelector(selectAllBoxes);
  const dispatch = useDispatch();
  const { filterMap, setFilterMap } = useSearchFilterParams();

  useEffect(() => {
    if (selectedProperty?.propertyId) {
      dispatch(fetchAllBoxes({ propertyId: selectedProperty.propertyId }));
    }
  }, [dispatch, selectedProperty?.propertyId]);

  const refreshList = useCallback(() => {
    dispatch(fetchAllBoxes({ propertyId: selectedProperty?.propertyId ?? "" }));
  }, [dispatch, selectedProperty?.propertyId]);

  const onFilterMapChange = useCallback(
    (filters: FilterMapInterface) => {
      setFilterMap(filters);
    },
    [setFilterMap]
  );

  return (
    <>
      <Box
        sx={{
          backgroundColor: "background.default",
          minHeight: "100%",
          py: 4
        }}
      >
        <Container maxWidth={settings.compact ? "xl" : false}>
          <Grid container spacing={3}>
            <Grid
              alignItems="center"
              container
              justifyContent="space-between"
              spacing={3}
              item
              xs={12}
            >
              <PageTitle subTitle={selectedPropertiesPreview} title={t("title__boxes")} />
            </Grid>
            <Grid container alignItems="center" pl={3} marginTop={1}>
              <Grid item xs={12} style={{ maxWidth: 480 }}>
                <Grid container>
                  <FreeTextFilter filterMap={filterMap} onFilterMapChange={onFilterMapChange} />
                </Grid>
              </Grid>
            </Grid>
            <BoxTabs boxes={boxes} refreshList={refreshList} />
          </Grid>
        </Container>
      </Box>
    </>
  );
};
